import axios from 'axios';
import { DOMAIN_URL } from '../constants';
import { CREATE_ACTU_FAIL, CREATE_ACTU_REQUEST, CREATE_ACTU_SUCCESS, DELELTE_ACTU_FAIL, DELELTE_ACTU_REQUEST, DELELTE_ACTU_SUCCESS, EDIT_ACTU_FAIL, EDIT_ACTU_REQUEST, EDIT_ACTU_SUCCESS, GET_ACTU_FAIL, GET_ACTU_SUCCESS, LIST_ACTU_REQUEST } from '../types/actu';
import { LIST_FAIL, LIST_REQUEST, LIST_SUCCESS } from '../types/projects';
import { GET_TRANSAC_REQUEST, GET_TRANSAC_SUCCESS, LIST_TRANSAC_ACTU_SUCCESS, LIST_TRANSAC_FAIL, LIST_TRANSAC_REQUEST } from '../types/transaction';
import { GET_FAIL, GET_REQUEST, GET_SUCCESS } from '../types/users';





export const userget = (id) => async (dispatch,getState) => {
  try {
    dispatch({
      type: GET_REQUEST,
    });
const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };


    const { data } = await axios.get(`${DOMAIN_URL}/api/users/${id}/`, config);

     
    dispatch({
      type: GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_FAIL,
      payload:error ,
    });
  }
};
export const users = () => async (dispatch,getState) => {
  try {
    dispatch({
      type: LIST_REQUEST,
    });

 
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token  ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${DOMAIN_URL}/api/users/`, config);

    dispatch({
      type: LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_FAIL,
      payload: error//error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
