import { useState } from "react";

import { Modal} from "react-bootstrap";


function Modaler(props) {
   const {onCancel,onConfirm,show,onClose}=props;
 /*   const [shower,setShower]=useState(show);
   
   const [onCloser,setonCloser]=useState(show);

   const   setShowes=()=>{

    setonCloser(true);
    setShower(false);

   }
 */
   

   return  (
    <>
    <Modal centered size="lg" show={show} onHide={onClose}>
        <Modal.Header closeButton >
            <Modal.Title className="fw-bold">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
        <h2 className="fw-bold">Voulez-vous vraiment supprimer?</h2>

        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Annuler</button>
            <button type="button" onClick={onConfirm} className="btn btn-primary">Supprimer</button>
        </Modal.Footer>
    </Modal>        </>
)




}
export default Modaler