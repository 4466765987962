import { Fragment, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';
import { slider } from '../actions/ SliderActions.js';


const SliderListCall = () => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** Get data on mount
  useEffect(() => {
    dispatch(slider());
  }, [dispatch]);

  return null;
};

export default memo(SliderListCall);
