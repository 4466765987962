import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddNewUserModal from "../../components/common/AddNewUserModal";
import PageHeader from "../../components/common/PageHeader";
import { LeadersListData } from "../../components/Data/AppData";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import ActuListCalls from "../../redux/calls/ActuListCalls";
import Modaler from "../../components/common/Modal";
import { actu, actudelete } from "../../redux/actions/actuActions";
import { slider, sliderdelete, sliderget } from "../../redux/actions/ SliderActions";
import SliderListCalls from "../../redux/calls/SliderListCalls";


import { access_rules_cm } from "../../redux/roles";


 const SliderList=() =>{
    let navigate = useNavigate();
    const dispatch= useDispatch();
    const initialVals={

        "isAddUserModal":false,
        "currentid":0

    }

    const listSlider = useSelector((state) => state.listSlider);
      
    const { loading, error, SLIDERLIST } = listSlider;
    const [values,setValues]=useState(initialVals)
    const [isConfirmed,setIsConfirmed]=useState(false)
    const  {userData}  = useSelector((state) => state.userLogin);

    const setisAddUserModalTrue  = (id)=>{

    setValues({
        'isAddUserModal':true,
        'currentid':id
    });
       // setcurrentid(id)
    }
    useEffect(() =>  {
     
      
      
        if(isConfirmed === true){
        
            console.log(' reload!')
          //  dispatch(actudelete(values.currentid))
             setValues({
                'isAddUserModal':false,
                'currentid':0,
    
            });
    
           setIsConfirmed(false)
          // dispatch(actu());
//refreshme();
           //    alert('reload!')
           //ACTULIST.filter(item => item.id != values.currentid)

           
           //dispatch(actu());
           console.log('listitem!')
         //  window.location.reload();
          // const reload=()=>window.location.reload();
        //  navigate('/dashboard/listactu', { replace: true });


        }
    else{
        dispatch(slider());
        console.log(' ending!')

    }
     

      }, []); 
/*     useEffect(() => {
        dispatch(slider());

      }, [values.isAddUserModal]);
 */
      const Delete = async (id)  => {

        await dispatch(sliderdelete(id));
         setValues({
             'isAddUserModal':false,
             'currentid':0,
         });
         dispatch(slider())
      
     return   ;  //    navigate(`/dashboard/listactu/`, {  repalce:true });
     ;
     }

     /*  const Delete = (id) => {
        dispatch(sliderdelete(id));
        setValues({
            'isAddUserModal':false,
            'currentid':0,
        });
        dispatch(slider());

       // dispatch(sliderget());
    
    } */
        //navi

    const toComponentB = (id) => {
        navigate(`/dashboard/editslider/${id}`, {  repalce:true });
        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      };
    const ProjectDataList={

title:'Liste des sliders',
columns:[
    {
        
        name: "Titre",
        selector:(row)=>row.title,
        sortable: true,
        cell:row=><><span className="fw-bold ms-1">{row.title}</span></>,
        minWidth:"200px"
    },
    {
        
        name: "Description",
        selector:(row)=>row.description,
        sortable: true,
    },
    {
        
        name: "button",
        selector:(row)=>row.button,
        sortable: true,
    },
    
    {
        
        name: "link",
        selector:(row)=>row.link,
        sortable: true,
    },
  
    {
        name: "ACTION",
        selector: (row)=>row.id,
        sortable: true,
        cell:(row)=><div className="btn-group" role="group" aria-label="Basic outlined example">


{  access_rules_cm.includes(userData) ?  
                        <button type="button" onClick={() => {
                            toComponentB(row.id);
                          }} className="btn btn-outline-secondary"><i className="icofont-edit text-success"></i></button>:null

              

                                  
                 
                    } 

{   access_rules_cm.includes(userData) ?  
                                 <button type="button" onClick={() => {
                                    setisAddUserModalTrue(row.id);}} className="btn btn-outline-secondary deleterow"><i className="icofont-ui-delete text-danger"></i></button>
                                        :null

              

                                  
                 
                    } 
              
           </div>
    }
],
rows:
SLIDERLIST.map(p => {

    return { title:p.title,
          description:p.description.substring(0,50)+'...',
          button:p.button,
          link:p.link,
          id:p.id,


          
      }
  })
    }





        return(
            access_rules_cm.includes(userData)? 
            <div className="container-xxl">
                <PageHeader headerTitle="Liste des sliders"  />
                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
{/*                             <SliderListCalls /> 
 */}
                                <DataTable
                                title={ProjectDataList.title}
                                columns={ProjectDataList.columns}
                                data={ProjectDataList.rows}
                                defaultSortField="title"
                                pagination
                                selectableRows={false}
                                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
/*                                 highlightOnHover={true}
 *//*                                 onRowClicked={()=>{this.setState({ isAddUserModal:true })}}
 */                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modaler onCancel={()=>{setIsConfirmed(false);}} onConfirm={()=>{setIsConfirmed(true);Delete(values.currentid)} }onClose={ values.isAddUserModal } show={values.isAddUserModal} />

{/*                 <AddNewUserModal show={ this.state.isAddUserModal } onClose={()=>{ this.setState({ isAddUserModal:false }) }} />
 */}            </div>
      :  <>  </>)
    }

    export default SliderList;