import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loginReducer, logout } from './reducers/authReducers';
import { projectCreateReducer, projectDeleteReducer, projectEditReducer, projectGetReducer, projectsReducer } from './reducers/projectReducers';
import { actuCreateReducer, actuDeleteReducer, actuEditReducer, actuGetReducer, actuReducer, createActuReducer} from './reducers/actuReducers';
import { domainCreateReducer, domainDeleteReducer, domaineditReducer, domainGetReducer, domainReducer } from './reducers/domainReducers';
import { transactGetReducer, transactReducer } from './reducers/transactReducers ';
import { userGetReducer, userReducer } from './reducers/usersReducers ';
import { sliderCreateReducer, sliderDeleteReducer, sliderEditReducer, sliderGetReducer, sliderReducer } from './reducers/sliderReducers';
const reducer = combineReducers({
  userLogin: loginReducer,
  createActu: actuCreateReducer,
  createSlider: sliderCreateReducer,

  createDomain: domainCreateReducer,
  logout: logout ,
  deleteProject: projectDeleteReducer,
  deleteDomain: domainDeleteReducer,
  deleteSlider:sliderDeleteReducer,

  deleteActu: actuDeleteReducer,
  listProject: projectsReducer,
  listSlider: sliderReducer,

  getProject: projectGetReducer,
  getSlider: sliderGetReducer,

  getActu: actuGetReducer,
  getDomain: domainGetReducer,
  getTransac: transactGetReducer,
  getTransac: userGetReducer,


  createProject: projectCreateReducer,

  listActu: actuReducer,
  listTransac: transactReducer,
  listUser: userReducer,


  listDomain: domainReducer,
  editProject:projectEditReducer,
  editActu:actuEditReducer,
  editDomain:domaineditReducer,
  editSlider:sliderEditReducer,




});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
const userDataFromStorage = localStorage.getItem('userData') ? localStorage.getItem('userData') : null;


const initialState = {
  userLogin: { userInfo: userInfoFromStorage,userData: userDataFromStorage },

 };
const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools({ mageAge: 200 })(applyMiddleware(...middleware)));

export default store;
