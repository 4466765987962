import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import AddNewUserModal from "../../components/common/AddNewUserModal";
import PageHeader from "../../components/common/PageHeader";
import { LeadersListData } from "../../components/Data/AppData";
import { useState } from 'react';
import ProjectsListCalls from "../../redux/calls/ProjectsListCalls";
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import TransactListCalls from "../../redux/calls/TransactListCalls";
import _ from 'lodash'
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import logoOm from "../../assets/images/om.png";

import logoWave from "../../assets/images/wave.png";
import logoCB from "../../assets/images/cartebleu.png";
import { Badge, Button } from "react-bootstrap";

import { format, formatDistanceToNow } from 'date-fns';
import { ButtonBase } from "@mui/material";
import moment from "moment";
import GeneralChartCard from "../../components/Dashboard/GeneralChartCard";
import BrandInfoSmallcard from "../../components/Dashboard/BrandInfoSmallcard";
import NavItem from "rsuite/esm/Nav/NavItem";
import { fr } from "date-fns/locale";

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
   //alert(JSON.stringify(array[0]))
	const keys = Object.keys(array[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function downloadCSV(array) {
	const link = document.createElement('a');
    if(array.length ===0 )   {
        alert("informations non disponibles");
        return;

    }      

	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null){
        return;
    } 

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}


const Export = ({ onExport }) =>                    
<Button onClick={e => onExport(e.target.value)} className="btn-block btn-light lift text-uppercase rs-picker-toggle rs-btn" >Exporter</Button>

/* const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
); */
 const SearchBar =({ filterText, onFilter, onClear }) =>{

    return(
        <div className="input-group flex-nowrap input-group-lg">
            <button type="button" className="input-group-text" id="addon-wrapping"><i className="fa fa-search"></i></button>
            <input type="search" value={filterText}
			onChange={onFilter} className="form-control" placeholder="Recherche" aria-label="search" aria-describedby="addon-wrapping" />
            <button type="button" className="input-group-text add-member-top"  onClick={onClear}><i className="fa fa-plus"></i></button>
        </div>
    )
 }


 const TransactionList=() =>{
    let navigate = useNavigate();
    const current = new Date();
  
    const listTransac = useSelector((state) => state.listTransac);

    const { loading, error, TRANSACLIST } = listTransac;
    const { Preview,setPreview } = useState(false)

    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [value, setValue] = React.useState([
       // new Date('2017-02-01 01:00:00'),
       // new Date('2017-02-02 14:00:00')
        new Date(moment('01 jul 2022').format('DD MMM YYYY HH:mm')),
        new Date(moment().format('DD MMM YYYY HH:mm')),

       // moment('01 jul 2022').format('DD MMM YYYY HH:mm:ss'),
      //  moment().format('DD MMM YYYY HH:mm:ss')
       //format(new Date(''),'dd MMM YYYY HH:mm')
      ]);
      const [selected, setSelected] = useState('nothing');
      const [selectedStatut, setSelectedStatut] = useState('nothing');

 
	const filteredItems = TRANSACLIST.filter(
		item => item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase()) , 
       
	).filter(
        item => value !=null &&
        
       // alert(   )
       new Date(moment(item.date).format('DD MMM YYYY HH:mm')).getTime()  > value[0].getTime() &&
       new Date(moment(item.date).format('DD MMM YYYY HH:mm')).getTime()  < value[1].getTime()

     //   new Date(item.date,'DD MMM YYYY HH:mm').getTime()
      //      < value[0].getTime()
        // moment(value[0]).format('DD MMM YYYY HH:mm').getTime() 
    ).filter(
        item => selected != 'nothing' ?  (item.provider === selected):item
    ).filter(
        item => selectedStatut != 'nothing' ?  (item.status === selectedStatut):item
    );

    const handleChange = event => {
        setSelected(event.target.value);
      };
      const handleChangeStatut = event => {
        setSelectedStatut(event.target.value);
      };
      const [totalglobal, setTotalglobal] = useState();
      const [totalcb, setTotalcb] = useState(0);

      const [totalOrangeMoney, setTotalOrangeMoney] = useState();
      const [totalWave, setTotalWave] = useState();


      useEffect(() => {
        let amount=0;
        const total=filteredItems.length == 0 ? 0: (filteredItems.filter(item => selectedStatut != 'nothing'? item.status === selectedStatut:item).reduce((total,currentItem) =>  total = total + parseInt(currentItem.amount) , 0 ));
        const totalOM=filteredItems.length == 0 ? 0: (filteredItems.filter(
            item => item.provider === "PMOrangeMoney" && (selectedStatut != 'nothing'? item.status === selectedStatut:true)).reduce((total,currentItem) =>  total = total + parseInt(currentItem.amount) , 0 ));
            const totalWave=filteredItems.length == 0 ? 0: (filteredItems.filter(
                item => item.provider === "PMWave" &&  (selectedStatut != 'nothing'?item.status === selectedStatut:true)).reduce((total,currentItem) =>  total = total + parseInt(currentItem.amount) , 0 ));
                const totalCb=filteredItems.length == 0 ? 0: (filteredItems.filter(
                    item => item.provider != "PMWave" && item.provider != "PMOrangeMoney" && (selectedStatut != 'nothing'?item.status === selectedStatut:true)).reduce((total,currentItem) =>  total = total + parseInt(currentItem.amount) , 0 ));
        
         
     
        // const sum = filteredItems.length == 0 ? 0: filteredItems.map(datum => datum.amount).reduce((a, b) => a + b)
    
        setTotalglobal(total)
        setTotalOrangeMoney(totalOM)
        setTotalWave(totalWave)
        setTotalcb(totalCb)

        //Runs on the first render
        //And any time any dependency value changes
      }, [filteredItems]);

   // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, []);

    /* const toComponentB = (id) => {
        navigate(`/dashboard/editproject/${id}`, {  repalce:true });
        //return <Navigate to={`/dashboard/editproject/${id}`} replace />;
      }; */

      const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        const setDating = (value) => {

         setValue(
           [
            moment(value[0]).format('DD MMM YYYY HH:mm'),
            moment(value[1]).format('DD MMM YYYY HH:mm')
           ] 
            
            )
            
        };
    
        return (
            <>
               <div className="container-xxl">

   <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
   <div className="col-8">

                    <SearchBar onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />

                    </div>
                    <div className="col-12">

            </div> 

            </div>      </div> 
            </> );

    }, [filterText, resetPaginationToggle]);
    const ProjectDataList={

title:'Liste des transactions',
columns:[
    {
        
        name: "# commande",
        selector:(row)=>row.ref_commande,
        sortable: true,
        cell:row=><><span className="fw-bold ms-1">{row.ref_commande}</span></>,
        minWidth:"200px"
    },
    {
        
        name: "Date",
        selector:(row)=>row.date,
        sortable: true,
        cell:row=><><span className="fw-bold ms-1">{format(new Date(row.date), 'dd MMM yyyy HH:mm',{locale:fr})}</span></>,

    },  
    {
        
        name: "Téléphone",
        selector:(row)=>row.phone,
        sortable: true,
    },
    {
        
        name: "Montant",
        selector:(row)=>row.amount,
        sortable: true,
    },
   
    {
        
        name: "Mode paiement",
        selector:(row)=>row.provider,
        sortable: true,
        maxWidth:"70px",

        cell:row=><>
        
        {row.provider=='PMWave'?   <img src={logoWave} alt="hero-img" height="30px" />
: row.provider=='PMOrangeMoney'?         <img src={logoOm} alt="hero-img" height="30px"/>
:  <img src={logoCB}  height="30px"/> }
        </>,


    },  
    {
        
        name: "Statut",
        selector:(row)=>row.status,
        sortable: true,
        maxWidth:"70px",

        cell:row=><>
        {row.status=='PENDING'? 
                        <span class="badge bg-warning">En cours</span>


    :row.status=='FAILED'?                                 <span class="badge bg-danger">Echoué</span>

    :                                <span class="badge bg-success">Terminé</span>

}
        </>,


    }, {
        
        name: "Projet",
        selector:(row)=>row.projet,
        sortable: true,
        maxWidth:"100px"

    },
    
   /*  {
        name: "ACTION",
        selector: (row)=>row.id,
        sortable: true,
        cell:(row)=><div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button"  className="btn btn-outline-secondary"><i className="icofont-edit text-success"></i></button>
                </div>
    } */
],
rows:
filteredItems.map(p => {

    return { ref_commande:p.ref_commande,
        name:p.name,
          phone:p.phone,
          projet:_.isEmpty(p.project)?'Contribution':p.project['title'],
          date:p.date,
          amount:p.amount,
          date:p.date,
          status:p.status,
          provider:p.provider,
          date:p.date,



          id:p.id,


          
      }
  })
    }





        return(
            <div className="container-xxl">
                      <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-4">
                    <div className="col">
                        <BrandInfoSmallcard title="Total" value={totalglobal} iconClass="icofont-data fs-3" />
                    </div>
                    <div className="col">
                        <BrandInfoSmallcard title="Total Carte Bancaire" value={totalcb} iconClass="icofont-chart-flow-2 fs-3" />
                    </div>
                    <div className="col">
                        <BrandInfoSmallcard title="Total Orange Money" value={totalOrangeMoney} iconClass="icofont-chart-flow-2 fs-3" />
                    </div>
                    <div className="col">
                        <BrandInfoSmallcard title="Total Wave" value={totalWave} iconClass="icofont-tasks fs-3" />
                    </div>
                </div>
                <PageHeader headerTitle="Liste des transactions"  />
                <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-4">
                    <div className="col">
                    <label className="form-label">Date</label>

                    <DateRangePicker  value={value} onChange={setValue} format="dd MMM yyyy HH:mm" />

                    </div>
                    <div className="col">
                    <label className="form-label">Mode de paiement</label>

                    <select className="form-select" value={selected} onChange={handleChange}>
                                      
                                      <option value="nothing">----</option>
                                      <option value="PMOrangeMoney">Orange Money</option>
                                      <option value="PMWave">Wave</option>
                                      <option value="Card">Carte Bancaire</option>
                                  </select>
                    </div>
                    <div className="col">
                    <label className="form-label">Statut</label>

                    <select className="form-select" value={selectedStatut} onChange={handleChangeStatut}>
                                      
                                      <option value="nothing">----</option>
                                      <option value="FAILED">Échoué</option>
                                      <option value="PENDING">En cours</option>
                                      <option value="COMPLETED">Terminé</option>
                                  </select>
                    </div>
                    <div className="col">
                    <label className="form-label">Exporter</label>

                    <Export onExport={() => downloadCSV(filteredItems.length === 0?[]:filteredItems)} />
                    
                    </div>
                </div>
                
             

                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                  
                            <div className="card-body">
                     
                            <TransactListCalls /> 

                                <DataTable
                                title={ProjectDataList.title}
                                columns={ProjectDataList.columns}
                                data={ProjectDataList.rows}
                                pagination
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
                                defaultSortField="ref_commande"
                                subHeaderComponent={subHeaderComponentMemo}
                                fixedHeaderScrollHeight="600"
                               // actions={actionsMemo} 

                                selectableRows={false}
                                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
/*                                 highlightOnHover={true}
 *//*                                 onRowClicked={()=>{this.setState({ isAddUserModal:true })}}
 */                                />
                            </div>
                        </div>
                    </div>
                </div>
{/*                 <AddNewUserModal show={ this.state.isAddUserModal } onClose={()=>{ this.setState({ isAddUserModal:false }) }} />
 */}            </div>
        )
    }

    export default TransactionList;