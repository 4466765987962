import { Fragment, useEffect, memo } from 'react';

import { useDispatch } from 'react-redux';

import { actu } from '../actions/actuActions.js';
import { transaction } from '../actions/transactActions .js';
import { users } from '../actions/userActions.js';

const TransactListCall = () => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** Get data on mount
  useEffect(() => {
    dispatch(users());
  }, [dispatch]);

  return null;
};

export default memo(TransactListCall);
