import axios from 'axios';
import { access_rules_member } from '../roles';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
} from '../types/auth';

import { DOMAIN_URL } from '../constants';

export const login = (username, password) => async (dispatch,getState) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

   const { data } = await axios.post(`${DOMAIN_URL}/auth-token/`, { username, password }, config);

   localStorage.setItem('userInfo', JSON.stringify(data));

  const a=data.token
  const configuser = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Token  ${a}`,

    },
  };

  const datas = await axios.get(`${DOMAIN_URL}/api/users/me/`, configuser).then(
    response => {
      console.log(response.data.type)

      if(access_rules_member.includes(response.data.type)){
        var error={'message':"Acces refusé"}
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userData');

        console.log('gougall')
    
        dispatch({
          type: LOGIN_FAIL,
          payload: error,
        });
       console.log("gouguaess")
      }
    else{
      console.log("wouy")
      localStorage.setItem('userData',response.data.type)
      console.log(response.data.type)
      
      var dataglobal= { "data1":data,"data2":response.data.type}
      dispatch({
        type: LOGIN_SUCCESS,
        payload: dataglobal,
      });
      console.log('thiéppp')
     
    
    } 
      
    }



  );
 


  
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload:  error,
    });
  }
};

export const register = (firstName, lastName, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${DOMAIN_URL}/api/v1/accounts/register/`,
      { firstName, lastName, email, password },
      config
    );

    dispatch({
      type: REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: REGISTER_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('userData');

  dispatch({ type: LOGOUT });
};
